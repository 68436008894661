<template>
  <div>
    <MainStepper e1="3" />
    <v-data-table
      :headers="headers"
      :items="items"
      sort-by="name"
      class="elevation-1"
      loading-text="Loading... Please wait"
    >
      <template v-slot:top>
        <v-toolbar flat class="pt-2">
          <v-col cols="6">
            <v-toolbar-title
              class="
                primary--text
                text-uppercase
                font-weight-bold
                justify-center
              "
            >
              <v-icon class="mr-2 mt-n1" color="error">mdi-domain</v-icon
              >Prequalifications
            </v-toolbar-title>
            <p class="grey--text ml-8 mt-n2">
              List of all supplier registration applications
            </p>
          </v-col>
          <v-col cols="6">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  dark
                  class="text-capitalize ml-1 float-right"
                  :loading="loading"
                  @click="goToSupplierSelection"
                  elevation="0"
                  fab
                  x-small
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-format-list-bulleted-square</v-icon>
                </v-btn>
              </template>
              <span>Back to supplier selection</span>
            </v-tooltip>
            <v-text-field
              label="search..."
              v-model="search"
              append-icon="mdi-magnify"
              dense
              width="54"
              color="primary"
              class="float-right"
            ></v-text-field>
          </v-col>

          <v-spacer></v-spacer>
        </v-toolbar>
        <v-progress-linear
          indeterminate
          color="primary"
          v-if="loading"
        ></v-progress-linear>
        <v-divider></v-divider>
      </template>
      <template v-slot:[`item.applicant.company_logo_url`]="{ item }">
        <v-img
          max-height="64"
          max-width="64"
          :src="item.applicant.company_logo_url"
          class="my-3 mr-auto"
          rounded
          outlined
          :lazy-src="item.applicant.company_logo_url"
        ></v-img>
      </template>
      <template v-slot:no-data>
        <a class="error--text text--darken-1">
          No applicant found for this tender</a
        >
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <div class="my-1">
          <a
            color="primary"
            class="mx-1 primary--text text--darken-1 text-decoration-underline"
            dark
            outlined
            text
            @click="editItem(item)"
          >
            Go To Details
          </a>
        </div>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import PrequalificationService from "../../services/prequalification.service";
import MainStepper from "../../components/MainStepper.vue";

export default {
  components: { MainStepper },
  data: () => ({
    name: "BusinessTypes",
    loading: true,
    items: [],
    editedIndex: -1,
    editedItem: {
      name: "",
      attachment: "",
      attachment_file: [],
    },
    defaultItem: {
      name: "",
      attachment: "",
      attachment_file: [],
    },
    remember: 0,
    timeout: 2000,
    dialog: false,
    valid: true,
    rules: {
      select: [(v) => !!v || "An item should be selected"],
      select2: [(v) => v.length > 0 || "Atleast one item should be selected"],
      required: [
        (v) => !!v || "Field is required",
        (v) => (v && v.length >= 3) || "Min 3 characters",
      ],
      file: [
        (v) => !!v || "File is required",
        (v) => (v && v.length > 0) || "File is required",
      ],
    },
    headers: [
      {
        text: "#",
        align: "start",
        filterable: true,
        value: "id",
      },
      {
        text: "Logo",
        value: "applicant.company_logo_url",
      },
      {
        text: "Company Name",
        value: "applicant.company.company_name",
      },
      {
        text: "Applicant Name",
        value: "applicant.name",
      },
      {
        text: "Phone",
        value: "applicant.phone",
      },
      {
        text: "Location",
        value: "applicant.company.physical_location",
      },
      {
        text: "Actions",
        align: "center",
        value: "actions",
        sortable: false,
      },
    ],
  }),
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New" : "Edit";
    },
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    getLoading() {
      return this.loading;
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  created() {
    this.index();
  },
  methods: {
    goToSupplierSelection() {
      this.$router.push("/supplier-selection");
    },
    openDocument(item) {
      console.log(item);
      this.$router.replace(item.attachment);
    },
    handleLogout() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/login");
    },
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    index() {
      return PrequalificationService.index().then(
        (response) => {
          if (response.status == 200) {
            this.items = response.data.data;
            this.loading = false;
          } else {
            this.items = [];
            console.log(response.data.error);
          }
        },
        (error) => {
          this.items = [];
          console.log(error);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.$router.push("/pre-application-preview/" + item.id);
      // this.dialog = true;
    },
    deleteItem(item) {
      const index = this.items.indexOf(item);
      var deleteUser = confirm("Are you sure you want to delete this item?");
      if (deleteUser && this.items.splice(index, 1)) {
        PrequalificationService.delete(item).then(
          (response) => {
            if (response.status == 200) {
              this.$store.dispatch("alert/success", response.data.message);
              this.close();
            } else {
              this.$store.dispatch("alert/error", response.data.message);
            }
          },
          (error) => {
            if (error.response.status == 422) {
              this.$store.dispatch("alert/error", error.response.data.message);
            }
            if (error.response.status == 401) {
              this.handleLogout();
            }
            console.log(error);
            this.$store.dispatch("alert/error", error.response.data.message);
          }
        );
      }
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    async handleFileUpload(file) {
      console.log("uploading...");
      await this.getBase64(file).then(
        (data) => (this.editedItem.attachment = data)
      );
      //   this.$tender_service.addDocument(formData).then(
      //     (response) => {
      //       console.log(response);
      //       if (response.status == 200) {
      //         this.$store.dispatch("alert/success", "Tender Document uploaded!");
      //         this.getTender();
      //         this.loading = false;
      //       } else {
      //         this.$store.dispatch("alert/error", response.message);
      //         this.loading = false;
      //       }
      //     },
      //     (error) => {
      //       this.loading = false;
      //       if (error.response.status == 422) {
      //         console.log(error.response.data.errors);
      //         this.message = error.response.data.message;
      //       } else {
      //         this.message =
      //           (error.response && error.response.data) ||
      //           error.message ||
      //           error.toString() ||
      //           "Internal Server Error";
      //       }
      //       this.$store.dispatch("alert/error", this.message);
      //       this.loading = false;
      //     }
      //   );
    },

    save() {
      this.loading = true;
      this.$refs.form.validate();
      if (this.valid) {
        // edit
        if (this.editedIndex > -1) {
          Object.assign(this.items[this.editedIndex], this.editedItem);
          PrequalificationService.update(this.editedItem).then(
            (response) => {
              if (response.status == 200) {
                this.$store.dispatch("alert/success", response.data.message);
                this.loading = false;
                this.index();
                this.close();
              } else {
                this.$store.dispatch("alert/error", response.data.message);
                this.index();
                this.loading = false;
              }
            },
            (error) => {
              if (error.response.status == 422) {
                this.$store.dispatch(
                  "alert/error",
                  error.response.data.message
                );
                this.loading = false;
                this.index();
              }
              if (error.response.status == 401) {
                this.handleLogout();
                this.loading = false;
                this.index();
              }
              console.log(error);
              this.$store.dispatch("alert/error", error.response.data.message);
            }
          );
        } else {
          // create
          PrequalificationService.store(this.editedItem).then(
            (response) => {
              if (response.status == 200) {
                this.items.push(this.editedItem);
                this.$store.dispatch(
                  "alert/success",
                  "Item created successfully"
                );
                this.loading = false;
                this.index();
                this.close();
              } else {
                this.$store.dispatch("alert/error", response.data.message);
                this.index();
              }
            },
            (error) => {
              if (error.response.status == 422) {
                this.$store.dispatch(
                  "alert/error",
                  error.response.data.message
                );
                this.loading = false;
                this.index();
              }
              if (error.response.status == 401) {
                this.handleLogout();
                this.loading = false;
                this.index();
              }
              console.log(error);
              this.$store.dispatch("alert/error", error.response.data.message);
            }
          );
        }
      } else {
        this.loading = false;
        this.index();
      }
    },
  },
};
</script>
