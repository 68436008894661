var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('MainStepper',{attrs:{"e1":"3"}}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"sort-by":"name","loading-text":"Loading... Please wait"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"pt-2",attrs:{"flat":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-toolbar-title',{staticClass:"\n              primary--text\n              text-uppercase\n              font-weight-bold\n              justify-center\n            "},[_c('v-icon',{staticClass:"mr-2 mt-n1",attrs:{"color":"error"}},[_vm._v("mdi-domain")]),_vm._v("Prequalifications ")],1),_c('p',{staticClass:"grey--text ml-8 mt-n2"},[_vm._v(" List of all supplier registration applications ")])],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-capitalize ml-1 float-right",attrs:{"color":"primary","dark":"","loading":_vm.loading,"elevation":"0","fab":"","x-small":""},on:{"click":_vm.goToSupplierSelection}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-format-list-bulleted-square")])],1)]}}])},[_c('span',[_vm._v("Back to supplier selection")])]),_c('v-text-field',{staticClass:"float-right",attrs:{"label":"search...","append-icon":"mdi-magnify","dense":"","width":"54","color":"primary"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-spacer')],1),(_vm.loading)?_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary"}}):_vm._e(),_c('v-divider')]},proxy:true},{key:"item.applicant.company_logo_url",fn:function(ref){
var item = ref.item;
return [_c('v-img',{staticClass:"my-3 mr-auto",attrs:{"max-height":"64","max-width":"64","src":item.applicant.company_logo_url,"rounded":"","outlined":"","lazy-src":item.applicant.company_logo_url}})]}},{key:"no-data",fn:function(){return [_c('a',{staticClass:"error--text text--darken-1"},[_vm._v(" No applicant found for this tender")])]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"my-1"},[_c('a',{staticClass:"mx-1 primary--text text--darken-1 text-decoration-underline",attrs:{"color":"primary","dark":"","outlined":"","text":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" Go To Details ")])])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }